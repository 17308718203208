import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { UiStore } from '../../../../core/store/ui.store';
import { IconComponent } from '../../layout/widgets/icon/icon.component';
import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [IconComponent, SpinnerComponent, TranslateModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() type: 'add' | 'edit' | 'delete';
  @Input() isLoading: boolean = false;
  @Input() label: string;
  @Input() loadingLabel: string = '..';
  @Output() clickRequest = new EventEmitter<any | undefined>();

  constructor(public uiStore: UiStore) {}

  requestClick(item?: any) {
    this.clickRequest.emit(item);
  }
}
