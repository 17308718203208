@if (!isLoading) {
  <button type="submit" (click)="requestClick()" class="btn btn-primary btn-theme me-2 ">
    @if(type=='add'){
      <img src="assets/images/icons/icon-plus.svg" />
    }
    {{ label }}
  </button>
}@else {
  <button class="btn btn-primary btn-theme me-2" type="button" disabled>
    <app-spinner></app-spinner>
    {{ loadingLabel | translate}}
  </button>
}
